module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BodilessJS","short_name":"Bodiless","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../node_modules/@bodiless/gatsby-theme-bodiless/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@canvasx/gatsby-plugin-onetrust/gatsby-browser.js'),
      options: {"plugins":[],"mainScriptDisabled":true,"scriptUrl":"//optanon.blob.core.windows.net/consent/d52c443f-58e0-498d-a661-f4b7a695b01d-test.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
